import * as React from "react";
import { useEffect } from "react";

const NikeConfig = () => {
    useEffect(
        () => {
            window.location.assign('https://prjctsolaris.gitbook.io/nike-monitor/');
        }
    )
    return(
        <>
        </>
    )
}

export default NikeConfig;